<template>
  <div class="main">
    <Title>企业荣誉</Title>
    <div class="box1">
      <div class="honor_list">
        <ul>
          <li class="list_item flex" v-for="(honor, i) in honorList" :key="i">
            <div class="honor_pic">
              <el-image :src="honor.img" :preview-src-list="[honor.img]">
              </el-image>
            </div>
            <div class="list_item_r honor_content">
              <h4>
                <a :href="honor.link" target="_blank">
                  {{ honor.content }}
                </a>
              </h4>
              <div class="date">
                {{ honor.date }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="box1" v-if="false">
      <div v-for="(itemd, index) in data" :key="index" class="flex wrap">
        <div class="col3" v-for="item in itemd" :key="item[0]">
          <ImagePreview
            :height="'90%'"
            :width="'90%'"
            class="hvr-push img"
            :src="require('../../assets/Goin/honor/' + item[0])"
            :srcList="[require('../../assets/Goin/honor/' + item[0])]"
          >
            <p class="img-text">{{ item[1] }}</p>
          </ImagePreview>
        </div>
      </div>
    </div>
    <!-- <More @more = "more"></More> -->
  </div>
</template>
<script>
import img1 from "@/assets/Goin/honor/honor1.png";
import img2 from "@/assets/Goin/honor/honor2.png";
import img3 from "@/assets/Goin/honor/honor3.png";
import img4 from "@/assets/Goin/honor/honor4.png";
import img5 from "@/assets/Goin/honor/honor5.png";
import img6 from "@/assets/Goin/honor/honor6.png";
import honor241 from "@/assets/Goin/honor/honor241.jpg";
import img242 from "@/assets/Goin/honor/24-2.jpg";
import img243 from "@/assets/Goin/honor/24-3.jpg";
// import img244 from "@/assets/Goin/honor/24-4.jpg";
import img245 from "@/assets/Goin/honor/24-5.jpg";
// import img246 from "@/assets/Goin/honor/24-6.jpg";
import img247 from "@/assets/Goin/honor/24-7.jpg";
// import img248 from "@/assets/Goin/honor/24-8.jpg";
// import img7 from "@/assets/Goin/honor/honor7.png"
export default {
  data() {
    return {
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
      img5: img5,
      img6: img6,
      // img7:img7,
      imgList1: [img1],
      imgList2: [img2],
      imgList3: [img3],
      imgList4: [img4],
      imgList5: [img5],
      imgList6: [img6],
      // imgList7:[img7],
      data: [
        [
          ["1-1.jpg", "高新技术企业"],
          ["1-2.jpg", "司法鉴定许可证"],
        ],
        [
          ["2-1.jpg", "发明专利证书"],
          ["2-2.jpg", "发明专利证书"],
          ["2-3.jpg", "发明专利证书"],
        ],
        [
          ["3-1.jpg", "教学科研实践基地"],
          ["3-2.jpg", "智慧物证与物联网技术联合实验室"],
          ["3-3.jpg", "物证技术联合研发中心"],
          ["3-4.jpg", "司法鉴定战略合作单位"],
        ],
        [
          ["4-1.jpg", "CMA资质认定证书"],
          // ['4-2.jpg','CNAS能力验证结果通知'],
          // ['4-3.jpg','CNAS能力验证结果通知'],
          // ['4-4.jpg','CNAS能力验证结果通知'],
          ["4-5.jpg", "CMA资质认定证书"],
        ],
      ],
      honorList: [
        {
          img: honor241,
          content:
            "福建历思司法鉴定所荣获司法部“全国公共法律服务工作先进集体”称号",
          date: "2024年04月10日",
          link: "https://mp.weixin.qq.com/s/lqwHeLcuxwCXQeprUaOstA",
        },
        {
          img: img242,
          content: "“毒品新型快准检测平台”荣获2022年度“十大法务科技应用产品”",
          date: "2023年01月16日",
          link: "https://mp.weixin.qq.com/s/_cStUiUM_-k8opNf4q_LyQ",
        },
        {
          img: img243,
          content: "“物证智慧化保全交接系统”荣获2022年度“十大法务科技应用产品”",
          date: "2023年01月16日",
          link: "https://mp.weixin.qq.com/s/_cStUiUM_-k8opNf4q_LyQ",
        },
        {
          img: img245,
          content:
            "历思毒品创新快准检测平台荣获联合国工业发展组织全球科技创新大会奖银奖",
          date: "2022年12月13日",
          link: "https://mp.weixin.qq.com/s/gIdsWnLbI4M4ro8k2tOvrQ",
        },
        {
          img: img247,
          content: "历思智慧水质采样器入选“厦门市2022年绿色技术和产品”目录",
          date: "2022年06月21日",
          link: "https://mp.weixin.qq.com/s/MJ_C_hMwG_XQexcNQOMuDw",
        },
      ],
    };
  },
  methods: {
    more() {
      console.log("more");
    },
  },
};
</script>
<style src="../../layout.css" scoped></style>
<style scoped>
.main {
  margin: 0 auto;
  width: 80vw;
  margin-bottom: 2rem;
  max-width: 1600px;
}
.img {
  /* width:33.3%; */
  max-width: 350px;
}
.img-text {
  margin: 2rem;
}
.box1 {
  /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: self-end; */
}
@media screen and (max-width: 337px) {
  .box1 {
    justify-content: center;
  }
  .img {
    min-width: 200px;
  }
}
.honor_list {
  width: 1000px;
  margin: 20px auto;
  text-align: left;
}
.list_item {
  margin-top: 20px;
}
.flex {
  justify-content: space-between;
}
.honor_pic {
  width: 300px;
  height: auto;
}
.honor_pic img {
  width: 100%;
}
.list_item_r {
  width: 600px;
}
.list_item_r a {
  color: black;
  text-decoration: none;
}
.list_item_r a:hover {
  color: #284c91;
}
.date {
  font-size: 16px;
  cursor: pointer;
}
</style>
